import {
  boot as intercomBoot,
  shutdown as intercomShutdown,
  update as intercomUpdate,
} from '@intercom/messenger-js-sdk';
import Cookies from 'js-cookie';
import {createContext, ReactNode, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';
import {
  GetSSOAuthURL,
  GetSSOAuthURLResponse,
  PostUserCurrentRequest,
  PostUserCurrentResponse,
  PostUserLogout,
  PostUserLogoutResponse,
} from 'types/api';
import {User} from 'types/models';
import {AuthContextType} from 'utils/auth';
import {fetchApi} from 'utils/fetchApi';
import {getUser, removeUser, saveUser} from 'utils/localStorage';

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import {additiveAPI} from '../redux/services';
// TODO: import isn't resolving with absolute path
// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import {userSlice} from '../redux/userSlice';

export const AuthContext = createContext<AuthContextType>(undefined!);
export function AuthProvider({children}: {children: ReactNode}) {
  const navigate = useNavigate();
  const local_user = getUser();
  const [user, setUser] = useState<User | undefined>(local_user);
  const dispatch = useDispatch();

  // Keep intercom in sync with the user state.
  // Shutdown ensures that no data leaks between the two states.
  useEffect(() => {
    if (user) {
      // NOTE: For scenarios where the user is not present, the intercom
      // messenger is already loaded, and then a user becomes present, this call
      // seems to be insufficient to get the messenger to fetch the messages for
      // the actual user and operate in that context. However, since we redirect
      // for auth, that is not a concern as the redirect causes the page to load
      // with the user setup. if that changes in the future, this code may need
      // to change.
      intercomUpdate({
        app_id: window['INTERCOM_APP_ID'],
        user_id: user.id,
        name: user.name,
        email: user.email,
        createdAt: user.created_at,
        user_hash: user.intercom_user_hash,
      });
    } else {
      // NOTE: This does _not_ clear the local state of the messenger, which the
      // logout handlers are responsible for. This just restarts the messenger
      // with no parameters when relevant.
      intercomBoot({
        app_id: window['INTERCOM_APP_ID'],
      });
    }
  }, [user]);

  const fetchUser = (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => {
    fetchApi<PostUserCurrentRequest, PostUserCurrentResponse>({
      url: '/api/users/current/',
      method: 'GET',
      onSuccess: (user_response) => {
        setUser(user_response);
        saveUser(user_response);
        userSlice.actions.setUser(user_response);
        onSuccessCallback(user_response);
      },
      onError: (error) => {
        setUser(undefined);
        removeUser();
        userSlice.actions.clearUser(undefined);
        onErrorCallback(error);
      },
    });
  };

  const signin = (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => {
    const csrftoken = Cookies.get('csrftoken') ?? '';
    fetchApi<GetSSOAuthURL, GetSSOAuthURLResponse>({
      url: '/api/get_sso_auth_url',
      method: 'GET',
      headers: {
        'X-CSRFToken': csrftoken,
      },
      onSuccess: (json) => {
        onSuccessCallback(json);
        window.location.href = json['authorization_url'];
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    });
  };

  // Helper called after logout, on either success or error.
  const postLogout = () => {
    // When the user is not present (e.g. logout), shutdown intercom, which
    // totally clears local state so that there is no leaked information
    // outside the user's session.
    intercomShutdown();
    // Unset the user in the context and in local storage.
    removeUser();
    setUser(undefined);
    // Reset api state to invalidate all queries (cache) on logout
    // Note: This does not invalidate local component state but should not be an issue
    // since we refetch on mount or arg change
    dispatch(additiveAPI.util.resetApiState());
  };

  const signout = (onSuccessCallback: (json) => void, onErrorCallback: (error) => void) => {
    const csrftoken = Cookies.get('csrftoken') ?? '';

    fetchApi<PostUserLogout, PostUserLogoutResponse>({
      url: '/api/logout',
      method: 'POST',
      headers: {
        'X-CSRFToken': csrftoken,
      },
      onSuccess: (json) => {
        postLogout();
        onSuccessCallback(json);
        if ('logout_url' in json) {
          window.location.href = json['logout_url'] || URL_ROUTES.LOGIN;
        } else {
          navigate(URL_ROUTES.LOGIN);
        }
      },
      onError: (error) => {
        postLogout();
        onErrorCallback(error);
        navigate(URL_ROUTES.LOGIN);
      },
      retry_count: 3,
    });
  };

  const value = {user, signin, signout, fetchUser};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
