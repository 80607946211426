import {StyledLink} from 'components/link';
import {useContext, useState} from 'react';
import {Link, Outlet} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';
import {AuthContext} from 'utils/authProvider';
import {getEnvironment} from 'utils/environment';
import {removeUser} from 'utils/localStorage';

export interface DashboardLayoutProps {
  children: React.ReactNode;
  signOutButton?: boolean;
  homeLink?: boolean;
}

export function DashboardLayout({children, signOutButton, homeLink}: DashboardLayoutProps) {
  const {signout} = useContext(AuthContext);

  const signoutUser = () => {
    signout(
      (json) => {},
      (error) => {},
    );
  };

  return (
    <div className={'p-0 md:p-6 rounded-xl'}>
      <Outlet />
      <NonProdWarningBanner />
      <div className="mx-auto max-w-6xl p-4 md:p-10">
        <div className="flex justify-between items-center mb-8">
          <Link to={URL_ROUTES.HOME}>
            <img
              src="/images/logo_letterform_yw_trim.png"
              alt="Additive Logo"
              className="max-h-12"
            />
          </Link>
          {signOutButton && (
            <div
              className="ml-4 text-lg text-white hover:underline cursor-pointer"
              onClick={() => {
                signoutUser();
                removeUser();
              }}
            >
              Sign&nbsp;out
            </div>
          )}
        </div>
        {homeLink && (
          <div className="pb-6">
            <StyledLink strong theme="white" to={URL_ROUTES.HOME} text="‹ Home" />
          </div>
        )}
        <Content>{children}</Content>
      </div>
    </div>
  );
}

function Content({children}: {children: React.ReactNode}) {
  return <div className="mx-auto max-w-6xl">{children}</div>;
}

/**
 * A warning banner that is displayed for non-production environments.
 */
function NonProdWarningBanner() {
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) {
    return;
  }

  const env = getEnvironment();
  if (env !== 'development' && env !== 'staging') {
    return;
  }

  return (
    <div className="bg-yellow text-black text-center py-2">
      <strong>Warning:</strong> This is a non-production environment.{' '}
      <strong>Do not upload unredacted data.</strong>
      <button
        className="ml-2 text-black underline"
        onClick={() => {
          setDismissed(true);
        }}
      >
        Dismiss
      </button>
    </div>
  );
}
