/* eslint-disable react/no-children-prop */
import './index.css';

import * as Sentry from '@sentry/react';
import {AuthRedirect} from 'components/authRedirect';
import {RootLayout} from 'layouts/root';
import {Home} from 'pages/home';
import {Login} from 'pages/login';
import {NotFound} from 'pages/notFound';
import {Result} from 'pages/result';
import {Settings} from 'pages/settings';
import {Thanks} from 'pages/thanks';
import {Upload} from 'pages/upload';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Private} from 'routes/privateRoute';
import {AuthProvider} from 'utils/authProvider';
import {reportWebVitals} from 'utils/reportWebVitals';

import {store} from './redux/store';
import {URL_ROUTES} from './routes/urls';

if (window) {
  Sentry.init({
    dsn: window['REACT_SENTRY_DSN'],
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: window['REACT_SENTRY_TRACES_SAMPLE_RATE'],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // TODO: Set URLS when backend is setup with sentry
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: window['REACT_SENTRY_REPLAYS_SESSION_SAMPLE_RATE'],
    replaysOnErrorSampleRate: window['REACT_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE'],
  });
} else {
  /* eslint-disable-next-line no-console */
  console.warn('window is not defined. Skipping Sentry setup.');
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <ReduxProvider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route element={<RootLayout />}>
              {/* Public routes */}
              <Route path={URL_ROUTES.LOGIN} element={<Login />} />
              <Route path={URL_ROUTES.AUTH_CALLBACK} element={<AuthRedirect />} />
              {/* Private routes */}
              <Route path={URL_ROUTES.ROOT} element={<Private children={<Home />} />} />
              <Route path={URL_ROUTES.RESULT} element={<Private children={<Result />} />} />
              <Route path={URL_ROUTES.THANKS} element={<Private children={<Thanks />} />} />
              <Route path={URL_ROUTES.UPLOAD} element={<Private children={<Upload />} />} />
              <Route path={URL_ROUTES.SETTINGS} element={<Private children={<Settings />} />} />
              {/* Redirects */}
              <Route path={URL_ROUTES.HOME} element={<Navigate replace to={URL_ROUTES.ROOT} />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ReduxProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
