/**
 * UI Routes
 * Used for React Router and links.
 */

/* eslint sort-keys: "error" */
export const URL_ROUTES = {
  AUTH_CALLBACK: '/client_auth_callback',
  HOME: '/home',
  LOGIN: '/login',
  LOGOUT: '/logout',
  PRIVACY_POLICY: 'https://www.additive.ai/privacy-policy',
  RESULT: '/result/:jobId',
  ROOT: '/',
  SETTINGS: '/settings',
  TERMS_OF_SERVICE: 'https://www.additive.ai/terms-of-service',
  THANKS: '/thanks',
  UPLOAD: '/upload',
};
