import classNames from 'classnames';
import {ErrorMessage} from 'components/errorMessage';
import {ButtonInput} from 'components/form/input';
import {JobStatus, PatchedJobStatus} from 'components/jobStatus';
import {LINK_COLORS, StyledLink} from 'components/link';
import {useWindowFocus} from 'hooks/useWindowFocus';
import IconPlus from 'icons/plus.svg';
import IconSortArrow from 'icons/sort_arrow.svg';
import IconStatusInProgress from 'icons/status_in_progress.svg';
import {DashboardLayout} from 'layouts/dashboard';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {URL_ROUTES} from 'routes/urls';
import {Job} from 'types/models';
import {enforceAuth} from 'utils/auth';
import {getUser} from 'utils/localStorage';
import {formatDate, formatTimeString} from 'utils/time';

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import {useGetJobsQuery} from '../redux/services';

function EmailWithYellowDot({email}: {email: string}) {
  const splitText = email.split('.');

  const splitSpans = splitText.map((text, index) => {
    return (
      <span key={index}>
        {text}
        {index < splitText.length - 1 ? <span className="text-yellow">.</span> : ''}
      </span>
    );
  });
  return splitSpans;
}

function AuthStatus() {
  const user = getUser();
  if (!user) {
    return <p>You are not logged in.</p>;
  }
  const isDev = user.dev ? (
    <span className="text-gray">
      {' '}
      <Link to={URL_ROUTES.SETTINGS}> (dev)</Link>
    </span>
  ) : (
    <></>
  );

  return (
    <div className="flex justify-between text-white w-full overflow-hidden">
      <div className="text-6xl md:text-4xl font-black font-1955 overflow-hidden text-ellipsis">
        Welcome&nbsp;
        {/* When on mobile, add a line break between "Welcome" and the name to fit on the screen. */}
        <br className="lg:hidden" />
        <Link to={URL_ROUTES.SETTINGS}>
          <EmailWithYellowDot email={user.email} />
        </Link>
        !{isDev}
      </div>
    </div>
  );
}

/**
 * PatchedJob is a patched version of the Job API response.
 */
interface PatchedJob extends Omit<Job, 'created_at' | 'updated_at' | 'status'> {
  hasOutput: boolean;
  // Convert date fields from "string" type to "Date"
  created_at: Date;
  updated_at?: Date;
  status: PatchedJobStatus;
}

// TODO: We should update the API to return a better job response, but for now we'll patch it here
// - Adds a status field
// - Adds a name field
// - Sorts the jobs by date DESC
function hackPatchAPIResponse(jobs: Job[]): PatchedJob[] {
  const EXAMPLE_PREFIX = 'Additive_';
  const EXAMPLE_SUFFIX = '_20240109-0021';

  return jobs
    .map((job) => {
      const firstOutput = job.outputs?.[0];
      const jobNameWithoutSuffix = firstOutput?.name?.split('.').slice(0, -1).join('.');

      // Newer jobs have a name field.
      // Older jobs hack to get the string between prefix and suffix lengths
      // TODO: Remove this if we don't care about old jobs
      const jobName = job.name
        ? job.name
        : jobNameWithoutSuffix
          ? jobNameWithoutSuffix.slice(EXAMPLE_PREFIX.length, -EXAMPLE_SUFFIX.length)
          : 'No Name';

      const createdAtDate = new Date(job.created_at);

      // Patch jobs to be "UNCOMPLETED" if they are older than 24 hours and still "PENDING"
      const jobStatus: PatchedJobStatus =
        createdAtDate &&
        job.status === 'PENDING' &&
        Date.now() - createdAtDate.getTime() > 24 * 60 * 60 * 1000
          ? 'UNCOMPLETED'
          : job.status;

      return {
        id: job.id,
        outputs: job.outputs,
        hasOutput: !!job.outputs?.length,
        status: jobStatus,
        created_at: createdAtDate,
        updated_at: job.updated_at ? new Date(job.updated_at) : undefined,
        name: jobName,
      };
    })
    .reverse();
}

export function Home() {
  const {data: jobsApiData, error, isLoading, refetch} = useGetJobsQuery();
  const dispatch = useDispatch();
  const [dateSortState, setDateSortState] = useState<'asc' | 'desc'>('desc');

  // If the user switches back to the tab, refetch the jobs
  useWindowFocus(() => {
    refetch();
  });

  // If there are pending jobs in the list, poll the API for job updates
  const POLL_INTERVAL_MS = 5_000;
  useEffect(() => {
    const interval = setInterval(() => {
      const hasPendingJob = jobsApiData?.some(
        (job) =>
          job.status === 'PENDING' &&
          // job is created within 24 hours (old pending jobs are ignored)
          Date.now() - new Date(job.created_at).getTime() < 24 * 60 * 60 * 1000,
      );
      if (hasPendingJob) refetch();
    }, POLL_INTERVAL_MS);
    return () => clearInterval(interval);
  }, [dispatch, isLoading, jobsApiData, error, refetch]);

  if (enforceAuth(error)) return;

  // Patch the job API response
  const jobs = jobsApiData
    ? hackPatchAPIResponse(jobsApiData).sort((a, b) => {
        return dateSortState === 'desc'
          ? b.created_at.getTime() - a.created_at.getTime()
          : a.created_at.getTime() - b.created_at.getTime();
      })
    : undefined;

  return (
    <DashboardLayout signOutButton>
      <div className="flex flex-col md:flex-row items-center justify-between my-12">
        <AuthStatus />
        <div className="w-full md:w-auto mt-8 md:my-0">
          <Link to={URL_ROUTES.UPLOAD}>
            <ButtonInput full>
              <div className="flex items-center justify-center md:py-0 py-2">
                <div className="w-100">
                  <IconPlus />
                </div>
                <span className="pl-2">New&nbsp;Workpaper</span>
              </div>
            </ButtonInput>
          </Link>
        </div>
      </div>
      <ErrorMessage error={error} />
      <table className="text-white w-full">
        <thead>
          <tr className="md:border-b-2 border-white">
            <th className="pb-2 pr-2 text-left">Your Workpaper</th>
            <th className="pb-2 lg:text-left text-center">Status</th>
            <th
              className={`pb-2 pl-2 text-left cursor-pointer ${LINK_COLORS}`}
              onClick={() => {
                setDateSortState(dateSortState === 'asc' ? 'desc' : 'asc');
              }}
            >
              Date{' '}
              <span
                className={classNames('inline-block', {
                  'transform rotate-180': dateSortState === 'desc',
                })}
              >
                <IconSortArrow />
              </span>
            </th>
          </tr>
        </thead>
        {jobs && jobs.length > 0 ? (
          <tbody>
            {jobs.map((job, index) => {
              const jobURL = `/result/${job.id}`;
              const jobResultText = job.name ? `${job.name} Workpaper Results` : 'Untitled';
              const jobBackgroundColor = index % 2 === 0 ? 'bg-gray-darkest' : 'bg-black';
              return (
                <tr
                  key={job.id}
                  className={classNames(jobBackgroundColor, {
                    'text-gray': job.status === 'FAILED' && !job.hasOutput,
                  })}
                >
                  <td className="pl-3 mr-2 py-3">
                    {job.hasOutput ? (
                      <StyledLink to={jobURL} theme="white" text={jobResultText} />
                    ) : (
                      <span
                        className="cursor-not-allowed italic uppercase"
                        title="Workpaper has no output"
                      >
                        {job.name ?? 'Untitled'}
                      </span>
                    )}
                  </td>
                  <td className="mr-2">
                    <JobStatus status={job.status} />
                  </td>
                  <td className="pl-2 ml-2">
                    <span title={formatTimeString(job.created_at)}>
                      {formatDate(job.created_at) || '—'}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={3}>
                <div className="text-white text-center mt-20" style={{fontSize: '48px'}}>
                  {isLoading ? (
                    <>
                      <div className="inline-block animate-spin">
                        <div className="py-10" style={{transform: 'scale(2)'}}>
                          <IconStatusInProgress />
                        </div>
                      </div>
                      <div className="text-center">Loading your workpapers…</div>
                    </>
                  ) : (
                    'No workpapers yet.'
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </DashboardLayout>
  );
}
