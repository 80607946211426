export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface Headers {
  [key: string]: string;
}

export interface FetchAPIParams<Request, Response> {
  url: string;
  headers?: Headers;
  body?: Request;
  method: HttpMethod;
  onSuccess: (data: Response) => void;
  onError?: (error: Error) => void;
  retry_count?: number;
}

// Note: Only works for JSON responses. Use fetch directly for other responses.
export async function fetchApi<Request, Response>(options: FetchAPIParams<Request, Response>) {
  const {url, onSuccess, onError, retry_count = 0} = options;

  try {
    const response = await fetch(url, {
      method: options.method,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data: Response = await response.json();
    onSuccess(data as Response);
  } catch (error) {
    if (retry_count < 1) {
      onError?.(error);
    } else {
      fetchApi({
        ...options,
        retry_count: retry_count - 1,
      });
    }
  }
}
