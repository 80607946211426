import {Link} from 'react-router-dom';

export function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-black text-white">
      <h1 className="text-3xl font-bold pb-3">404. Page not found.</h1>
      <span className="underline">
        <Link to={`/`}>Go home</Link>
      </span>
    </div>
  );
}
