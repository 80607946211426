import {configureStore} from '@reduxjs/toolkit';

import {additiveAPI} from './services';
import {userSlice} from './userSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    [additiveAPI.reducerPath]: additiveAPI.reducer,
  },
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(additiveAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
